import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import { TextColors, TextTypes } from "../constants";
import { Text } from "../Text";

import "./styles.scss";
import { Info } from "../Info";

export const FormField = ({
    children,
    className,
    labelClassName,
    label,
    sublabel,
    toolTip,
    toolTipPosition,
    errorMessage,
    errorTestId,
    ...props
}) => (
    <div className={createClassNameString(["apti-CL", "apti-FormField", className])} {...props}>
        <div className={"apti-FormField--label-container"}>
            <Text
                className={createClassNameString(["apti-FormField--label", labelClassName])}
                type={TextTypes.label}>
                {label}
            </Text>
            {sublabel && (
                <Text className="apti-FormField--label" type={TextTypes.paragraph}>
                    {sublabel}
                </Text>
            )}
            {toolTip && <Info position={toolTipPosition}>{toolTip}</Info>}
        </div>

        <div className="apti-FormField--input-container">{children}</div>
        <Text
            className="apti-FormField--error"
            type={TextTypes.small}
            color={TextColors.red}
            testId={errorTestId}>
            {errorMessage}
        </Text>
    </div>
);

FormField.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    sublabel: PropTypes.string,
    toolTip: PropTypes.element,
    toolTipPosition: PropTypes.string,
    errorTestId: PropTypes.string,
};

FormField.defaultProps = {
    children: <></>,
    className: undefined,
    labelClassName: undefined,
    label: "",
    sublabel: undefined,
    toolTip: undefined,
    toolTipPosition: "left",
    errorTestId: undefined,
};

import React, {useState} from "react";
import { ConsentDisclaimer } from "../../components/Common/ConsentDisclaimer";
import "./styles.scss";

import { createClassNameString } from "../utils";
import { Card } from "../Card";
import { ConsentToTreatment } from "../ConsentToTreatment";
import { Button } from "../Button";

const customDisclaimerPrefix = "By selecting “Agree & Continue,” you agree to aptihealth’s";

export const ConsentOnLogIn = ({
    className,
    title,
    subTitle,
    policyMetadata,
    onSubmit,
}) => {
    const [hasConsented, setHasConsented] = useState(false);
    const consentVersion = policyMetadata?.CONSENT_TO_TREATMENT?.current_version;
    
    return (
        <Card className={createClassNameString(["ConsentOnLogIn", className])}>
            <div className={"ConsentOnLogIn--title"}>{title}</div>
            <div className={"ConsentOnLogIn--sub-title"}>{subTitle}</div>
            <ConsentToTreatment
                className={"ConsentOnLogIn--consentToTreatment-container"}
                consentEvent={() => setHasConsented((prevState) => !prevState)}
                version={consentVersion}
            />
            <Button
                buttonType={"primary-v3"}
                className={"ConsentOnLogIn--button"}
                onClick={onSubmit}
                disabled={!hasConsented}>
                Agree & Continue
            </Button>
            <ConsentDisclaimer
                policyMetadata={policyMetadata}
                customDisclaimerPrefix={customDisclaimerPrefix}
                customClass={"ConsentOnLogIn--consentDisclaimer"}
            />
        </Card>
    );
};

ConsentOnLogIn.defaultProps = {
    onSubmit: () => {},
};
import React, { useEffect, useState } from "react";
import { ListBody, ListHeader } from "../../../UI/ListView";
import PropTypes from "prop-types";
import { ConsentListItems } from "./ConsentListItems";
import { connect } from "react-redux";

const MemberProfileConsentList = (props) => {
    const { consents } = props;
    const [consentData, setConsentData] = useState([]);

    const getPatientConsent = async () => {
        const legacyConsents = consents.filter((consent) => consent?.consent_form_version == null);
        setConsentData(legacyConsents);
    };

    useEffect(() => {
        getPatientConsent();
    }, []);

    const renderLegacyConsentList = () => {
        return (
            consentData?.length > 0 && (
                <ListBody classes="List__body_lg consent-list my-3">
                    <ListHeader title={"Legacy Consents"} />
                    <ConsentListItems consentData={consentData} />
                </ListBody>
            )
        );
    };

    return renderLegacyConsentList();
};

MemberProfileConsentList.propTypes = {
    patientDetails: PropTypes.object,
    profile: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(MemberProfileConsentList);

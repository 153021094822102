import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { createClassNameString } from "../utils";

export const RadioGroup = ({
    className,
    children,
    name,
    value,
    defaultValue,
    onChange,
    disabled,
    ...props
}) => {
    const [currentValue, setCurrentValue] = useState(value ? value : defaultValue);

    useEffect(() => {
        setCurrentValue(value ? value : defaultValue);
    }, [value, defaultValue]);

    const clickHandler = (e) => {
        setCurrentValue(e.target.value);
    };

    return (
        <div className={createClassNameString(["apti-RadioGroup", className])} {...props}>
            {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    name: name,
                    checked:
                        child.props.value === currentValue ||
                        (value === "" && child.props.value === defaultValue),
                    onClick: clickHandler,
                    onChange,
                    disabled: disabled,
                }),
            )}
        </div>
    );
};

RadioGroup.propTypes = {
    children: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

RadioGroup.defaultProps = {
    label: undefined,
    name: "",
    value: "",
    checked: false,
    onClick: () => {},
    disabled: false,
};

import React from "react";
import PropTypes from "prop-types";

import { createClassNameString, generateRandomId } from "../utils";

import { Image } from "../Image";
import { Popover } from "../Popover";
import { Portal } from "../Portal";

import "./styles.scss";
import { getAutomationDataAttr } from "../../utils/automationUtils";

export const Info = ({ children, className, position, testId, ...props }) => {
    let toolTipId = generateRandomId() + "-tooltip";
    if (testId && process.env.REACT_APP_ENV !== "production") {
        toolTipId = testId;
    }

    return (
        <div className={createClassNameString(["apti-Info", position, className])} {...props}>
            <Image
                src="icons/info_button.svg"
                alt="info"
                data-for={toolTipId}
                data-tip="info-tooltip"
                {...getAutomationDataAttr(testId)}
            />
            <Portal>
                <Popover toolTipId={toolTipId} className={"apti-Info--popover"} position={position}>
                    {children}
                </Popover>
            </Portal>
        </div>
    );
};

Info.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    testId: PropTypes.string,
};

Info.defaultProps = {
    children: <></>,
    className: undefined,
    position: "left",
    testId: undefined,
};

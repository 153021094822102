// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { Form } from "formik";
import Button from "../../../UI/Button";
import FormikInput from "../../../UI/FormikInput";
import FormikInputFormat from "../../../UI/formikMobileInput";
import Recaptcha from "react-recaptcha";
import InputError from "../../../UI/InputError";
import * as yup from "yup";
import * as Yup from "yup";
import _ from "lodash";
import {
    adultDobValidationSchemaFactory,
    yup_password_factory,
} from "../../../../utils/yupSchemaUtils";
import images from "../../../../utils/images";
import InterestInGuardianship, {
    GUARDIANSHIP_PROGRAM_TYPE,
} from "../../../Common/InterestInGuardianship";
import { connect } from "react-redux";
import { ConsentEmail, ConsentSMS, CustomForm } from "../../../../component-library";
import { ConsentDisclaimer, createImplicitConsent } from "../../../Common/ConsentDisclaimer";
import { ConsentToTreatment } from "../../../../component-library/ConsentToTreatment";
import { createAcknowledgement } from "../../../../utils/userAgreementUtil";
import publicRequests from "../../../../APIRequests/public";
import { CONSENT_TO_TREATMENT } from "../../../Policy";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

const PrimaryAccountInfo = (props) => {
    const { accountData, setAccountData, nextStep } = props;

    let reCaptchaRef = React.createRef();

    const [mounted, setMounted] = useState(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [recaptchaError, setRecaptchaError] = useState(null);
    const [interestedInGuardianship, setInterestedInGuardianship] = useState(false);
    const [consent, setConsent] = useState({
        email: null,
        sms: null,
        treatment: null,
    });
    const [policyMetadata, setPolicyMetadata] = useState({});

    useEffect(() => {
        (async () => {
            setPolicyMetadata(await publicRequests.get_policy_metadata());
        })();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setMounted(true);
        }, 500);

        if (props.configs.OVERRIDE_RECAPTCHA) {
            setRecaptchaVerified(true);
        }
    }, [props.configs.OVERRIDE_RECAPTCHA]);

    const submitHandler = async (formData, actions) => {
        if (!recaptchaVerified) {
            setRecaptchaError("reCAPTCHA is Required");
            return;
        }

        formData.interested_in_guardianship = interestedInGuardianship;

        if (interestedInGuardianship && !formData.guardianship_program_type) {
            formData.guardianship_program_type = GUARDIANSHIP_PROGRAM_TYPE.DEFAULT;
        }

        const implicitConsents = await createImplicitConsent(policyMetadata);
        formData["consent"] = { ...implicitConsents, ...consent };

        setAccountData(formData);
        nextStep();
    };

    const consentHandler = async (policyName, policyVersion) => {
        let currConsent = _.cloneDeep(consent);
        const newConsent = {
            ...(await createAcknowledgement()),
            version: policyVersion,
        };

        if (consent[policyName] == null) {
            currConsent[policyName] = [newConsent];
            setConsent(currConsent);
        } else {
            currConsent[policyName] = null;
            setConsent(currConsent);
        }
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };

        return (
            <Form id="selfsignupformik">
                <div className={"PrimaryAccountInfo--form-container"}>
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "first_name",
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                label: "First Name",
                                placeholder: "Enter First Name",
                                labelClasses: "fs-16",
                            },
                        }}
                        testId={"self-sign-up-first-name"}
                    />
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "last_name",
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                label: "Last Name",
                                placeholder: "Enter Last Name",
                                labelClasses: "fs-16",
                            },
                        }}
                        testId={"self-sign-up-last-name"}
                    />
                    <FormikInputFormat
                        {...formikInputProps}
                        formEl={{
                            name: "dob",
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                label: "Date of Birth",
                                placeholder: "MM/DD/YYYY",
                                labelClasses: "fs-16",
                            },
                        }}
                        value={formikProps && formikProps.values["dob"]}
                        onChange={async (val) => {
                            await formikProps.setFieldValue("dob", val.value);
                            formikProps.setFieldTouched("dob");
                        }}
                        testId={"self-sign-up-dob"}
                    />
                    <FormikInputFormat
                        {...formikInputProps}
                        formEl={{
                            name: "mobile",
                            elementType: "input",
                            elementConfig: {
                                type: "text",
                                label: "Mobile",
                                placeholder: "Enter Mobile",
                                labelClasses: "fs-16",
                            },
                        }}
                        value={formikProps && formikProps.values["mobile"]}
                        onChange={async (val) => {
                            await formikProps.setFieldValue("mobile", val.value);
                            formikProps.setFieldTouched("mobile");
                        }}
                        testId={"self-sign-up-mobile"}
                    />
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "email",
                            elementType: "input",
                            elementConfig: {
                                type: "email",
                                label: "Email",
                                placeholder: "Enter Email",
                                labelClasses: "fs-16",
                            },
                        }}
                        testId={"self-sign-up-email"}
                    />
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "password",
                            elementType: "input",
                            elementConfig: {
                                type: "viewablePassword",
                                label: "Create Password",
                                placeholder: "Enter Password",
                                labelClasses: "fs-16",
                            },
                        }}
                        testId={"self-sign-up-password"}
                    />
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "confirm_password",
                            elementType: "input",
                            elementConfig: {
                                type: "viewablePassword",
                                label: "Confirm Password",
                                placeholder: "Re-enter Password",
                                labelClasses: "fs-16",
                            },
                        }}
                        testId={"self-sign-up-confirm-password"}
                    />
                    <div className={"my-3 d-flex fs-15"}>
                        <InterestInGuardianship
                            interestedInGuardianship={interestedInGuardianship}
                            setInterestedInGuardianship={() =>
                                setInterestedInGuardianship(!interestedInGuardianship)
                            }
                            testId={"self-sign-up-interested-in-guardianship"}
                        />
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            <div className={"p-5"}>
                <div className={"fs-24 fw-bold text-center"}>Welcome to aptihealth!</div>
                <div className={"fs-18 text-center txt-gry"}>
                    Let’s get started by creating your account
                </div>
                <div className={"text-center mt-3"}>
                    <span>Already have an account?</span>
                    <Button
                        className={"Btn-link txt-pri apti-green-v2 text-decoration-underline"}
                        onClick={() => {
                            // redirect user to sign in
                            props.history.push("/auth/sign-in");
                        }}>
                        Log In
                    </Button>
                </div>
                <div className={"text-center my-4"}>
                    <img
                        src={images("./signup/progress-1.png")}
                        alt={"Step 1/3"}
                        className={"signup-progress-bar"}
                    />
                </div>
                <CustomForm
                    initialValues={accountData}
                    validationSchema={accountPart1Validation()}
                    onSubmit={submitHandler}
                    render={renderForm}
                />

                <div className={"my-3 fs-15 txt-gry"}>
                    <ConsentToTreatment
                        version={policyMetadata[CONSENT_TO_TREATMENT]?.current_version}
                        consentEvent={() =>
                            consentHandler(
                                "treatment",
                                policyMetadata[CONSENT_TO_TREATMENT]?.current_version,
                            )
                        }
                    />
                </div>

                <div className={"my-3 fs-15 txt-gry"}>
                    <span id={"self-signup-consent"}>
                        <ConsentEmail consentEvent={() => consentHandler("email")} />
                    </span>
                </div>

                <div className={"my-3 fs-15 txt-gry"}>
                    <span id={"self-signup-consent"}>
                        <ConsentSMS consentEvent={() => consentHandler("sms")} />
                    </span>
                </div>

                <div className={"mt-5 mb-4 text-center w-100"}>
                    <div>
                        {mounted && (
                            <Recaptcha
                                className="mx-auto recaptcha"
                                size={window.innerWidth < 768 ? "compact" : "normal"}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                ref={reCaptchaRef}
                                verifyCallback={() => {
                                    setRecaptchaError(null);
                                    setRecaptchaVerified(true);
                                }}
                            />
                        )}
                    </div>
                    <div className={"text-center my-2"}>
                        {recaptchaError && (
                            <InputError style={{ position: "relative" }}>
                                {recaptchaError}
                            </InputError>
                        )}
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <Button
                        form="selfsignupformik"
                        type="submit"
                        className="Btn Btn--pri self-signup-btn"
                        disabled={consent["treatment"] ? null : "disabled"}
                        {...getAutomationDataAttr("self-sign-up-primary-info-button")}>
                        Agree & Continue
                    </Button>
                </div>

                <ConsentDisclaimer policyMetadata={policyMetadata} />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps, {})(PrimaryAccountInfo);

const accountPart1Validation = () =>
    yup.object().shape({
        first_name: yup
            .string()
            .required("First name is required")
            .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed"),
        last_name: yup
            .string()
            .required("Last name is required")
            .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed"),
        dob: adultDobValidationSchemaFactory(
            "MMDDYYYY",
            "Must be a valid date",
            "To register a child for care aged 5-17, you must first register yourself. " +
                "Toggle the button below and you will be prompted to register your child when you finish.",
        ).required("Date of birth is required"),
        mobile: yup
            .string()
            .matches(/^\d{10}$/, "Contact number must be a valid US number")
            .required("Mobile number is required"),
        email: yup.string().email("Enter a valid email address").required("Email is required"),
        password: yup_password_factory(),
        confirm_password: Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("password"), null], "Password does not match"),
    });

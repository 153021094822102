// Copyright aptihealth, inc. 2021 All Rights Reserved
import React from "react";
import { createAcknowledgement, createAcknowledgementV2 } from "../../../utils/userAgreementUtil";
import { HIPAA, PRIVACY, TERMS_AND_CONDITIONS } from "../../Policy";

/**
 * @deprecated This function is only asynchronous due to fetching of client IP, which we no longer
 * do, see https://aptihealth.atlassian.net/issues/AT1-6695. Use createImplicitConsentV2 instead
 */
export const createImplicitConsent = async (policyMetadata) => {
    const consent = await createAcknowledgement();
    return {
        eula: [{ ...consent, version: policyMetadata[TERMS_AND_CONDITIONS]?.current_version }],
        privacy: [{ ...consent, version: policyMetadata[PRIVACY]?.current_version }],
        hipaa: [{ ...consent, version: policyMetadata[HIPAA]?.current_version }],
    };
};

export const createImplicitConsentV2 = (policyMetadata, onBehalfOfAcknowledgement = false) => {
    const policyMapping = {
        eula: TERMS_AND_CONDITIONS,
        privacy: PRIVACY,
        hipaa: HIPAA,
    };
    return Object.fromEntries(
        Object.entries(policyMapping).map(([key, value]) => [
            key,
            [
                createAcknowledgementV2({
                    onBehalfOfAcknowledgement,
                    policyVersion: policyMetadata[value]?.current_version,
                }),
            ],
        ]),
    );
};

export const ConsentDisclaimer = ({ policyMetadata, customDisclaimerPrefix = null, customClass = "" }) => {
    const renderDocLink = (text, policyPath, version) => {
        return (
            <a
                className={"apti-green-v2 fs-14 text-decoration-underline"}
                href={`/${policyPath}?version=${version}`}
                target="_blank"
                rel="noreferrer">
                {text}
            </a>
        );
    };

    const EulaLink = () => {
        return renderDocLink(
            "Terms and Conditions of Use",
            "terms-and-conditions",
            policyMetadata[TERMS_AND_CONDITIONS]?.current_version,
        );
    };

    const PrivacyPolicyLink = () => {
        return renderDocLink("Privacy Policy", "privacy", policyMetadata[PRIVACY]?.current_version);
    };

    const HipaaNoticeLink = () => {
        return renderDocLink(
            "HIPAA Notice of Privacy Practices",
            "hipaaprivacy",
            policyMetadata[HIPAA]?.current_version,
        );
    };

    let disclaimerPrefix = "By creating your account, you agree to aptihealth’s";

    if (customDisclaimerPrefix) {
        disclaimerPrefix = customDisclaimerPrefix;
    }

    return (
        <div className={`text-center mt-4 txt-gry fs-14 ${customClass}`}>
            {disclaimerPrefix} <EulaLink />, <PrivacyPolicyLink />, and <HipaaNoticeLink />
        </div>
    );
};

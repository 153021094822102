import React from "react";
import PropTypes from "prop-types";

import { createClassNameString, insuranceCompanyFilterOptions } from "../utils";
import { TextTypes } from "../constants";

import { FormField } from "../FormField";
import { Text } from "../Text";
import { Input } from "../Input";
import { Dropdown } from "../Dropdown";
import { RadioGroup, RadioOld } from "../Radio";
import { UploadFile } from "../UploadFile";

import "./styles.scss";
import { DateInput } from "../DateInput";
import { PRETTY_MAX_FILE_SIZE, VALID_IMAGE_EXTENSIONS } from "../../utils/fileUtil";
import { getAutomationDataAttr } from "../../utils/automationUtils";

const uploadFileInfoContent = `Please upload images of the front and back of your insurance card. Acceptable file formats: ${VALID_IMAGE_EXTENSIONS.join(
    ", ",
)}. Max file size: ${PRETTY_MAX_FILE_SIZE}`;
const childProfilePolicyHolderLabel = "Are you, the Parent/Guardian, the Policy Holder?";

export const AddInsurance = ({
    className,
    isChildProfile,

    // Formik props
    values,
    errors,
    touched,
    handleFocus,
    handleBlur,
    handleChange,

    // Component specific
    hideUploadFile,
    policyHolderLabel,

    insuranceCompanyOptions,

    files,
    onBrowseButtonClicked,
    onFileDelete,

    noInsurance,
    requiredFields,

    // Carrier Specific
    memberIdPlaceholder,
    memberIdTip,
    memberIdTooltipContents,
    groupIdTooltipContents,

    ...props
}) => {
    let insuranceDisabledClassName = "";
    if (noInsurance) {
        insuranceDisabledClassName = "apti-disabled";
    }

    return (
        <div className={createClassNameString(["apti-AddInsurance", className])} {...props}>
            <Text className="apti-AddInsurance--header" type={TextTypes.label}>
                Primary Insurance Information
            </Text>
            <div className="apti-AddInsurance--grid">
                <FormField
                    className="apti-AddInsurance--field"
                    label="Insurance Company"
                    errorMessage={touched.insuranceCompany ? errors.insuranceCompany : ""}
                    errorTestId={"insurance-company-error"}>
                    <Dropdown
                        name={"insuranceCompany"}
                        value={values.insuranceCompany}
                        options={insuranceCompanyOptions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        placeholder={"Select Insurance Company"}
                        error={touched.insuranceCompany && errors.insuranceCompany}
                        filterOptions={insuranceCompanyFilterOptions}
                        {...getAutomationDataAttr("insurance-company", "id")}
                    />
                </FormField>
                <FormField
                    className={createClassNameString([
                        "apti-AddInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label={
                        policyHolderLabel
                            ? policyHolderLabel
                            : isChildProfile
                            ? childProfilePolicyHolderLabel
                            : "Are you the Policy Holder?"
                    }
                    errorMessage={touched.policyHolder ? errors.policyHolder : ""}>
                    <RadioGroup
                        className={insuranceDisabledClassName}
                        name="policyHolder"
                        value={values.policyHolder}
                        defaultValue={"yes"}
                        onChange={handleChange}
                        disabled={noInsurance}>
                        <RadioOld
                            label="Yes"
                            value="yes"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            {...getAutomationDataAttr("insurance-policy-holder-yes")}
                        />
                        <RadioOld
                            label="No"
                            value="no"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            {...getAutomationDataAttr("insurance-policy-holder-no")}
                        />
                    </RadioGroup>
                </FormField>
                <FormField
                    className={createClassNameString([
                        "apti-AddInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label="Member ID"
                    errorMessage={touched.memberId ? errors.memberId : ""}
                    errorTestId={"insurance-member-id-error"}>
                    {memberIdTip && (
                        <div
                            className={"txt-gry mb-2"}
                            {...getAutomationDataAttr("insurance-member-id-tip")}>
                            {memberIdTip}
                        </div>
                    )}
                    <Input
                        className={insuranceDisabledClassName}
                        name="memberId"
                        placeholder={memberIdPlaceholder}
                        value={values.memberId}
                        error={touched.memberId && errors.memberId}
                        infoContent={memberIdTooltipContents}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={noInsurance}
                        testId={"insurance-member-id"}
                    />
                </FormField>
                <FormField
                    className={createClassNameString([
                        "apti-AddInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label="Group ID"
                    sublabel="(Optional)"
                    errorMessage={touched.groupId ? errors.groupId : ""}
                    errorTestId={"insurance-group-id-error"}>
                    <Input
                        className={insuranceDisabledClassName}
                        name="groupId"
                        placeholder="Enter Group ID"
                        value={values.groupId}
                        error={touched.groupId && errors.groupId}
                        infoContent={groupIdTooltipContents}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={noInsurance}
                        testId={"insurance-group-id"}
                    />
                </FormField>
                {requiredFields && requiredFields.includes("first_name") && (
                    <FormField
                        className="apti-AddInsurance--field"
                        label="First Name"
                        errorMessage={touched.firstName ? errors.firstName : ""}
                        errorTestId={"insurance-first-name-error"}>
                        <Input
                            className={insuranceDisabledClassName}
                            name="firstName"
                            placeholder="Enter First Name"
                            value={values.firstName}
                            error={touched.firstName && errors.firstName}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            disabled={noInsurance}
                            testId={"insurance-first-name"}
                        />
                    </FormField>
                )}
                {requiredFields && requiredFields.includes("last_name") && (
                    <FormField
                        className="apti-AddInsurance--field"
                        label="Last Name"
                        errorMessage={touched.lastName ? errors.lastName : ""}
                        errorTestId={"insurance-last-name-error"}>
                        <Input
                            className={insuranceDisabledClassName}
                            name="lastName"
                            placeholder="Enter Last Name"
                            value={values.lastName}
                            error={touched.lastName && errors.lastName}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            disabled={noInsurance}
                            testId={"insurance-last-name"}
                        />
                    </FormField>
                )}
                {requiredFields && requiredFields.includes("dob") && (
                    <FormField
                        className="apti-AddInsurance--field"
                        label="Date of Birth"
                        errorMessage={touched.dob ? errors.dob : ""}
                        errorTestId={"insurance-dob-error"}>
                        <DateInput
                            className={insuranceDisabledClassName}
                            name="dob"
                            placeholder="MM/DD/YYYY"
                            value={values.dob}
                            onChange={(e) =>
                                handleChange({ target: { name: "dob", value: e.value } })
                            }
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            error={touched.dob && errors.dob}
                            disabled={noInsurance}
                            testId={"insurance-dob"}
                        />
                    </FormField>
                )}
                {!hideUploadFile && (
                    <UploadFile
                        className={createClassNameString([
                            "apti-AddInsurance--upload",
                            insuranceDisabledClassName,
                        ])}
                        infoContent={uploadFileInfoContent}
                        files={files}
                        onBrowseButtonClicked={onBrowseButtonClicked}
                        onFileDelete={onFileDelete}
                        disabled={noInsurance}
                    />
                )}
            </div>
        </div>
    );
};

AddInsurance.propTypes = {
    className: PropTypes.string,
    isChildProfile: PropTypes.bool,
    insuranceCompanyOptions: PropTypes.array.isRequired,

    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,

    hideUploadFile: PropTypes.bool,
    defaultPolicyHolderAnswer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    files: PropTypes.arrayOf(PropTypes.string).isRequired,
    onBrowseButtonClicked: PropTypes.func.isRequired,
    onFileDelete: PropTypes.func.isRequired,

    noInsurance: PropTypes.bool,
    requiredFields: PropTypes.arrayOf(PropTypes.string),
};

AddInsurance.defaultProps = {
    className: undefined,
    isChildProfile: false,
    insuranceCompanyOptions: [],

    values: {},
    errors: {},
    touched: {},
    handleFocus: () => {},
    handleBlur: () => {},
    handleChange: () => {},

    hideUploadFile: false,
    defaultPolicyHolderAnswer: null,
    files: [],
    onBrowseButtonClicked: () => {},
    onFileDelete: () => {},

    noInsurance: false,
    requiredFields: null,
};

import React from "react";
import * as yup from "yup";
import { Image } from "../../../component-library";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

export const getCarrierMetadata = (carrierName) => {
    const metadata = {
        CDPHP: {
            url: "https://www.cdphp.com/",
            logo: "CDPHP.png",
            memberIdTip: "11 digits including 2-digit suffix. See (i) for example.",
            memberIdPlaceholder: "e.g. 12345678900",
            memberIdTooltipContents: (
                <Image
                    src="CDPHPHelper.svg"
                    alt="CDPHP Card ID and Suffix location. Medicaid members, please use 00 as your suffix number."
                    {...getAutomationDataAttr("insurance-member-id-info-cdphp")}
                />
            ),
            memberIdValidationSchema: yup
                .string()
                .required("Member ID is required")
                .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                .min(11, "Must be 11 digits.")
                .max(11, "Must be 11 digits."),
        },
        MVP: {
            url: "https://www.mvphealthcare.com/",
            logo: "MVP.png",
            memberIdTip: "11 digits. Can include letters.",
            memberIdPlaceholder: "e.g. AB123456789",
            memberIdValidationSchema: yup
                .string()
                .required("Member ID is required")
                .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                .min(11, "Must be 11 characters.")
                .max(11, "Must be 11 characters."),
        },
        Fidelis: {
            url: "https://www.fideliscare.org/",
            logo: "Fidelis.svg",
            memberIdTip: "11 digits. Omit any dashes.",
            memberIdPlaceholder: "e.g. 12345678900",
            memberIdValidationSchema: yup
                .string()
                .required("Member ID is required")
                .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                .min(11, "Must be 11 characters.")
                .max(11, "Must be 11 characters."),
        },
        default: {
            url: "",
            logo: "",
            memberIdTip: "",
            memberIdPlaceholder: "Enter Member ID",
            memberIdTooltipContents:
                "Each person covered by a health insurance plan has a unique ID number that allows healthcare providers to verify coverage and arrange payment for services. Health insurances use this ID to look up members and answer questions about claims and benefits. This ID is always on the front of the card.",
            memberIdValidationSchema: yup
                .string()
                .required("Member id is required")
                .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                .nullable(),
            groupIdTooltipContents:
                "Group ID is found on your health insurance card. If you purchase insurance through a healthcare exchange (marketplaces set up by the Affordable Care Act, sometimes called “Obamacare”), you might not have a group number.",
        },
    };
    const carrierShortName = carrierName
        ? Object.keys(metadata).find((key) => carrierName.includes(key))
        : null;
    return { ...metadata.default, ...metadata[carrierShortName] };
};

export const carrierMemberIdValidationSchema = yup.string().when("carrier", (carrier) => {
    const carrierMetadata = getCarrierMetadata(carrier);
    return carrierMetadata.memberIdValidationSchema;
});

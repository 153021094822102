// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import "./styles.scss";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const InputError = (props) => {
    let classes = "InputError ";
    if (props.classes) {
        classes += props.classes;
    }
    return (
        <span className={classes} style={props.style} {...getAutomationDataAttr(props.testId)}>
            {props.children}
        </span>
    );
};

export default InputError;

import React, { useEffect, useState } from "react";
import { ConsentOnLogIn } from "../../../component-library/ConsentOnLogIn";
import Spinner from "../../UI/Spinner";
import publicRequests from "../../../APIRequests/public";
import patientRequests from "../../../APIRequests/patient";
import {
    CONSENT_KEY_TO_POLICY_TYPE,
    createPolicyConsentEntry,
    createAcknowledgementV2,
    POLICY_CONSENT_TASK_TYPES,
} from "../../../utils/userAgreementUtil";

export const UpdatedPoliciesConsent = (props) => {
    const acknowledgementTask = props?.task;
    const policyType = acknowledgementTask?.policy_type;
    const [policyMetadata, setPolicyMetadata] = useState(null);
    const [keyId, setKeyId] = useState(0);

    const getPolicyConsentTaskBodyText = (task) => {
        const { target_patient_name, task_type } = task;
        return {
            [POLICY_CONSENT_TASK_TYPES.UPDATED_POLICIES]:
                "We’ve updated the terms of our policies. By selecting “Agree & Continue,” you confirm " +
                "that you have read and agree to the following policies.",
            [POLICY_CONSENT_TASK_TYPES.UPDATED_CONSENT_REQUIRED]:
                "Our records indicate that you recently turned 18. By selecting “Agree & Continue,” " +
                "you confirm that you have read and agree to the following policies.",
            [POLICY_CONSENT_TASK_TYPES.UPDATED_CHILD_CONSENT]:
                "We’ve updated the terms of our policies. By selecting “Agree & Continue,” you confirm " +
                "that you have read and agree to the following policies on behalf of your child, " +
                `${target_patient_name}.`,
        }[task_type];
    };

    useEffect(() => {
        const fetchPolicyMetadata = async () => {
            const result = await publicRequests.get_policy_metadata();
            setPolicyMetadata(result);
        };

        fetchPolicyMetadata();
    }, []);

    const appendConsent = async () => {
        const acknowledgement = createAcknowledgementV2({
            policyVersion: policyMetadata[policyType].current_version,
            onBehalfOfAcknowledgement: true,
        });

        const policyVersionsDisplayedToPatient = Object.keys(policyMetadata).reduce((acc, key) => {
            acc[CONSENT_KEY_TO_POLICY_TYPE[key]] = policyMetadata[key].current_version;
            return acc;
        }, {});

        const requestBody = createPolicyConsentEntry(
            acknowledgementTask.target_patient_id,
            acknowledgement,
            CONSENT_KEY_TO_POLICY_TYPE[policyType],
            policyVersionsDisplayedToPatient,
        );
        await patientRequests.append_patient_consent({ data: requestBody });
        props.onConsent(props.task);
        setKeyId((prev) => prev + 1);
    };

    return (
        <>
            {policyMetadata && props.task ? (
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <ConsentOnLogIn
                        title={props.task.label}
                        subTitle={getPolicyConsentTaskBodyText(props.task)}
                        policyMetadata={policyMetadata}
                        onSubmit={appendConsent}
                        key={`ConsentOnLogInKey-${keyId}`}
                    />
                </div>
            ) : (
                <Spinner />
            )}
        </>
    );
};

// Copyright aptihealth, inc. 2021 All Rights Reserved

import React from "react";
import "./styles.scss";
import { BrandedHeader } from "../BrandedHeader";
import {  POLICY_CONSENT_TASK_TYPES } from "../../../utils/userAgreementUtil";
import { UpdatedPoliciesConsent } from "../UpdatedPoliciesConsent";

const LogInTasks = (props) => {
    const currentTask = props.match.params.task_name;

    const tasksMap = {
        [POLICY_CONSENT_TASK_TYPES.UPDATED_POLICIES]: (
            <UpdatedPoliciesConsent task={props?.task} onConsent={props.onTaskCompleted} />
        ),
        [POLICY_CONSENT_TASK_TYPES.UPDATED_CHILD_CONSENT]: (
            <UpdatedPoliciesConsent task={props?.task} onConsent={props.onTaskCompleted} />
        ),
        [POLICY_CONSENT_TASK_TYPES.UPDATED_CONSENT_REQUIRED]: (
            <UpdatedPoliciesConsent task={props?.task} onConsent={props.onTaskCompleted} />
        ),
    };

    return (
        <>
            <BrandedHeader />
            {tasksMap[currentTask]}
        </>
    );
};


export default LogInTasks;

// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Fragment, useEffect, useState } from "react";
import FormikInput from "../UI/FormikInput";
import { Button } from "../../component-library/Button";
import * as Yup from "yup";
import { yup_password_factory } from "../../utils/yupSchemaUtils";
import { AuthWrapper } from "./index";
import { CustomForm } from "../../component-library/CustomForm";
import { connect } from "react-redux";
import { alreadyHaveACode } from "../../redux/actions/auth";
import { Link } from "../../component-library";

const emailValidationObject = {
    email: Yup.string().required("Email is required").email("Enter valid email id"),
};

const codeAndPassValidationObject = {
    confirmation_code: Yup.string().required("This field is required"),
    Password: yup_password_factory(),
    ConfirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("Password"), null], "Password does not match"),
};

const emailValidation = Yup.object().shape(emailValidationObject);

const codeAndPassValidation = Yup.object().shape(codeAndPassValidationObject);

const emailCodeAndPassValidation = Yup.object().shape({
    ...emailValidationObject,
    ...codeAndPassValidationObject,
});

const ChangePassword = (props) => {
    const hasEmail = props.hasEmail;

    const [pass, setPass] = useState("");
    const [passHasNum, setPassHasNum] = useState(false);
    const [passHasUpper, setPassHasUpper] = useState(false);
    const [passHasLower, setPassHasLower] = useState(false);
    const [passHasSpecial, setPassHasSpecial] = useState(false);
    const [passHasEightChars, setPassHasEightChars] = useState(false);

    const siftThroughEvents = (event) => {
        if (event.target.className.includes("is-password")) {
            setPass(event.target.value);
        }
    };

    useEffect(() => {
        setPassHasNum(/\d/.test(pass));
        setPassHasUpper(/[A-Z]/.test(pass));
        setPassHasLower(/[a-z]/.test(pass));
        setPassHasSpecial(/[\^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+\-]/.test(pass));
        setPassHasEightChars(pass.length >= 8);
    }, [pass]);

    return (
        <div>
            <h2 className="mb-4 reset-Pass-header">Reset your password</h2>
            <p style={{ fontSize: "16px", textAlign: "center", color: "Gray" }}>
                Enter the code you received in your email.
            </p>
            <CustomForm
                validationSchema={hasEmail ? codeAndPassValidation : emailCodeAndPassValidation}
                onSubmit={hasEmail ? props.setNewPassword : props.setNewPasswordWithEmail}
                initialValues={{ confirmation_code: "", Password: "", ConfirmPassword: "" }}
                render={(formikProps) => (
                    <form
                        onSubmit={formikProps.handleSubmit}
                        onChange={(e) => siftThroughEvents(e)}>
                        {!hasEmail && (
                            <>
                                <FormikInput
                                    {...props.topFormikProps}
                                    errors={formikProps.errors}
                                    touched={formikProps.touched}
                                    formEl={{
                                        name: "email",
                                        elementType: "input",
                                        autocomplete: "off",
                                        elementConfig: {
                                            labelClasses: "Forgot-PW-Input-Text",
                                            type: "text",
                                            label: "Email",
                                            placeholder: "Enter Email",
                                        },
                                    }}
                                />
                                <div style={{ marginBottom: "2rem" }}></div>
                            </>
                        )}
                        <FormikInput
                            {...props.topFormikProps}
                            errors={formikProps.errors}
                            touched={formikProps.touched}
                            formEl={{
                                name: "confirmation_code",
                                elementType: "input",
                                autocomplete: "off",
                                elementConfig: {
                                    labelClasses: "Forgot-PW-Input-Text",
                                    type: "text",
                                    label: "Reset Code",
                                    placeholder: "Enter Reset Code",
                                },
                            }}
                        />
                        <div style={{ marginBottom: "2rem" }}></div>
                        <div style={{ marginBottom: "1rem" }}>
                            <FormikInput
                                errors={formikProps.errors}
                                touched={formikProps.touched}
                                {...props.formikPasswordInputProps}
                                formEl={{
                                    name: "Password",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "viewablePassword",
                                        labelClasses: "Forgot-PW-Input-Text",
                                        label: "New Password",
                                        placeholder: "Enter New Password",
                                    },
                                }}
                            />
                        </div>
                        <p style={{ color: "Gray", fontSize: "12px", margin: "-5px 0 5px 0" }}>
                            Password must have:
                        </p>
                        <ul style={{ marginLeft: "1em", marginBottom: "2em" }}>
                            <li className={`${passHasNum ? "checked" : "unchecked"}`}>
                                One number
                            </li>
                            <li className={`${passHasUpper ? "checked" : "unchecked"}`}>
                                One uppercase character
                            </li>
                            <li className={`${passHasLower ? "checked" : "unchecked"}`}>
                                One lowercase character
                            </li>
                            <li
                                className={`${
                                    passHasSpecial ? "checked" : "unchecked"
                                }`}>{`One special character ^ $ * . [ ] { } ( ) ? “ ! @ # % & / \ , > < ${"'"} : ; | _ ~ ${"`"} = + -`}</li>
                            <li className={`${passHasEightChars ? "checked" : "unchecked"}`}>
                                Eight characters minimum
                            </li>
                        </ul>
                        <FormikInput
                            errors={formikProps.errors}
                            touched={formikProps.touched}
                            {...props.formikInputProps}
                            formEl={{
                                name: "ConfirmPassword",
                                elementType: "input",
                                elementConfig: {
                                    labelClasses: "Forgot-PW-Input-Text",
                                    type: "viewablePassword",
                                    label: "Confirm New Password",
                                    placeholder: "Confirm New Password",
                                },
                            }}
                        />
                        <div
                            className="ResetPassword__submit-btn-wpr d-flex"
                            style={{ marginTop: "1em" }}>
                            <Button
                                disabled={formikProps.isSubmitting}
                                type="submit"
                                style={{ fontSize: "16px", margin: "0 auto" }}
                                className="Btn Btn--pri Btn-sm mt-2 ml-auto">
                                Reset Password
                            </Button>
                        </div>
                        {!hasEmail && (
                            <div
                                style={{
                                    textAlign: "center",
                                    marginTop: "2.5em",
                                    fontSize: "14px",
                                    color: "Gray",
                                }}>
                                Didn't receive a reset code? Check your spam filter. <br /> If
                                you're still having trouble, contact{" "}
                                <a
                                    className="has-reset-code"
                                    href="mailto: support@aptihealth.com?subject=Web Support Request">
                                    support@aptihealth.com
                                </a>{" "}
                                for help.
                            </div>
                        )}
                    </form>
                )}
            />
        </div>
    );
};

const ForgotPassword = (props) => {
    const {
        emailFromPrevStep,
        currentStep,
        sendResetPasswordCode,
        setNewPassword,
        setNewPasswordWithEmail,
    } = props;

    const [email, setEmail] = useState(emailFromPrevStep || "");
    const [validEmail, setValidEmail] = useState(false);
    const [hasCode, setHasCode] = useState(false);

    const email_schema = Yup.object({
        email: Yup.string().required().email(),
    });

    useEffect(() => {
        email_schema.isValid({ email }).then((valid) => setValidEmail(valid));
    }, [email]);

    let formikInputProps = {
        inputClasses: "Input--sm no-vert-padding",
    };

    let formikPasswordInputProps = {
        inputClasses: "Input--sm no-vert-padding is-password",
    };

    let sendResetCode = (
        <Fragment>
            <p className="login-welcome my-3">Forgot your password?</p>
            <p className="fs-16 mb-2 my-3" style={{ textAlign: "center", color: "gray" }}>
                Enter your email to receive a reset code. Note: You’ll need to have verified your
                email to complete the process.
            </p>
            <CustomForm
                validationSchema={emailValidation}
                onSubmit={sendResetPasswordCode}
                initialValues={{ email: email }}
                render={(formikProps) => (
                    <form
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        onSubmit={formikProps.handleSubmit}>
                        <div>
                            <FormikInput
                                errors={formikProps.errors}
                                touched={formikProps.touched}
                                {...formikInputProps}
                                formEl={{
                                    name: "email",
                                    elementType: "input",
                                    autocomplete: "off",
                                    elementConfig: {
                                        labelClasses: "Forgot-PW-Input-Text",
                                        type: "email",
                                        label: "Email",
                                        placeholder: "Enter Email",
                                    },
                                }}
                            />
                        </div>
                        <div className="ResetPassword__submit-btn-wpr d-flex justify-content-center">
                            <Button
                                type="submit"
                                style={{ fontSize: "16px" }}
                                className={`Btn Btn--pri Btn-sm my-lg-5 my-3 ${
                                    validEmail && email.length > 0 ? "primary" : "gray"
                                }`}>
                                Send Reset Code
                            </Button>
                        </div>
                        <p
                            className={"has-reset-code"}
                            onClick={() => {
                                if (!validEmail) {
                                    setHasCode(true);
                                }
                                props.alreadyHaveACode(email);
                            }}>
                            Already Have a Code?
                        </p>

                        <p className={"my-4 fs-14"} style={{ textAlign: "center", color: "gray" }}>
                            If you need help, contact support at{" "}
                            <Link href="mailto:support@aptihealth.com">support@aptihealth.com</Link>{" "}
                            or <Link href="tel:8884543827">(888) 454-3827</Link>.
                        </p>
                    </form>
                )}
            />
        </Fragment>
    );
    const topFormikProps = {
        inputClasses: "Input--sm",
    };

    return (
        <AuthWrapper showArt={props.showSvgArt} refFromOuter={props.scrlWprRef} showBackLink={true}>
            <section className="ResetPassword">
                {currentStep === 1 && sendResetCode}
                {currentStep === 2 && (
                    <ChangePassword
                        hasEmail={!hasCode}
                        topFormikProps={topFormikProps}
                        setNewPasswordWithEmail={setNewPasswordWithEmail}
                        setNewPassword={setNewPassword}
                    />
                )}
            </section>
        </AuthWrapper>
    );
};

export default connect(null, { alreadyHaveACode })(ForgotPassword);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { api } from "../../../APIRequests";
import { getPatientProfile, updateJWT } from "../../../redux/actions/auth";
import { putPatientCardInfo } from "../../../redux/actions/patientCardInfo";
import { getQueryParams } from "../../../utils/filters";
import {
    displayElavonCreditCardModal,
    parsePaymentResponse,
} from "../../../utils/PaymentProvider/elavon";
import Captcha from "../Captcha/Captcha";

const MobileAddPayment = ({
    dispatch,
    location,

    auth,
    profile,

    getPatientProfile,
}) => {
    // For non-mobile testing
    if (!window.ReactNativeWebView) {
        window.ReactNativeWebView = {
            postMessage: () => {},
        };
    }

    const [modalShown, setModalShown] = useState(false);
    const [captchaVerificationToken, setCaptchaVerificationToken] = useState(false);

    const clearLocalStorage = () => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("refreshToken");
        window.localStorage.removeItem("accessToken");
    };

    const onBackendError = (response) => {
        clearLocalStorage();
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                error: true,
                type: "apti_api_error",
                message: "API backend error for '/payment/method' endpoint",
                response,
            }),
        );
    };
    const onError = (error) => {
        clearLocalStorage();
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                error: true,
                type: "modal_error",
                message: "Error",
                response: error,
            }),
        );
    };
    const onCancelled = () => {
        clearLocalStorage();
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                error: true,
                type: "modal_cancelled",
                message: "Elavon modal cancelled",
            }),
        );
    };
    const onDeclined = (response) => {
        clearLocalStorage();
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                error: true,
                type: "card_declined",
                message: "Declined",
                response,
            }),
        );
    };
    const onApproval = (response) => {
        const { username } = profile;
        dispatch(
            putPatientCardInfo({
                username: username,
                workflow: "MOBILE",
                ...parsePaymentResponse(response),
                onErrorCallback: onBackendError,
                finallyCallback: () => {
                    clearLocalStorage();
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            error: false,
                            type: "success",
                            message: "Success!",
                        }),
                    );
                },
            }),
        );
    };

    const handleElavonCreditCardModalException = (e) => {
        if (!e?.code) {
            // Treat any empty error codes as unknown server errors (such as Cloudflare 429s)
            clearLocalStorage();
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    error: true,
                    error_message: "Unexpected error adding card on file. Please try again later.",
                    type: "apti_api_server_error",
                    message: "API backend server error for '/payment/session_token' endpoint",
                }),
            );
        } else if (e?.code >= 500) {
            // Allow user to temporarily progress if error is outside their control (server error)
            clearLocalStorage();
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    error: true,
                    error_message: e.message,
                    type: "apti_api_server_error",
                    message: "API backend server error for '/payment/session_token' endpoint",
                }),
            );
        } else if (e?.code == 400 && e?.type === "ActivityException") {
            clearLocalStorage();
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    error: true,
                    error_message: e.message,
                    type: e.type,
                    message: e.message,
                }),
            );
        }
        throw e;
    };

    const displayLightBox = async () => {
        const { first_name, last_name, username } = profile;
        try {
            await displayElavonCreditCardModal(
                dispatch,
                username,
                first_name,
                last_name,
                () => {}, // setErrorMessage
                "MOBILE",
                onError,
                onCancelled,
                onDeclined,
                onApproval,
                false,
                captchaVerificationToken,
            );
        } catch (e) {
            handleElavonCreditCardModalException(e);
        }
    };

    useEffect(() => {
        if (profile && !modalShown && captchaVerificationToken) {
            displayLightBox();
            setModalShown(true);
        }
    }, [captchaVerificationToken]);

    useEffect(() => {
        if (auth.token) {
            getPatientProfile();
        }
    }, [auth.token]);

    useEffect(() => {
        const getJWTFromObfuscationId = async () => {
            try {
                const id = getQueryParams("id", location.search);
                const username = getQueryParams("username", location.search);

                const deobfuscatedJWTResponse = await api.obfusctaion.obfuscationGetWithUsername({
                    data: { id, username },
                });

                const { token, accessToken, refreshToken } = deobfuscatedJWTResponse.data;

                window.localStorage.setItem("token", token);
                window.localStorage.setItem("refreshToken", refreshToken);
                window.localStorage.setItem("accessToken", accessToken);
                dispatch(
                    updateJWT({
                        token,
                        accessToken,
                        refreshToken,
                    }),
                );
            } catch (error) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        error: true,
                        type: "apti_api_error",
                        message: "API backend error for '/obfuscation/get_with_username' endpoint",
                        response: error,
                    }),
                );
            }
        };

        getJWTFromObfuscationId();
    }, []);

    return <Captcha setCaptchaVerificationToken={setCaptchaVerificationToken} />;
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators({ getPatientProfile }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileAddPayment));

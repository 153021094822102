// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import Message from "../Message";
import PropTypes from "prop-types";

export const ChatBody = (props) => {
    const {
        channel,
        loadOlderMessages,
        lastSeenRef,
        messageRefs,
        messages,
        messagesEndRef,
        unreadIndex,
        hasMoreMessages,
    } = props;

    const unreadMessageHeader = (
        <div ref={lastSeenRef} className="Messages__Chat__Body__LastSeen">
            -----Last Seen-----
        </div>
    );

    const userAvatar = (
        <div>
            <img src={channel.coverImage} alt="avatar" className="avatar-small" />
        </div>
    );

    const renderMessage = (message) => {
        const { displayName, formattedTime, inner_class, isRead } = message;

        return (
            <>
                {userAvatar}
                <div className={inner_class}>
                    <div>
                        {displayName && (
                            <span>
                                <strong>{displayName}</strong>
                            </span>
                        )}
                        <p style={{ whiteSpace: "pre-line" }} className="colored-text">
                            <Message message={message.message} />
                        </p>
                    </div>
                    <div className="float-right fs-11 colored-text">
                        <span>
                            {formattedTime} {isRead && <span>✓</span>}
                        </span>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="Messages__Chat__Body">
            {hasMoreMessages && (
                <div className={"Messages__Chat__Body--load-older-messages-container"}>
                    <a
                        onClick={loadOlderMessages}
                        className={"Messages__Chat__Body--load-older-messages-link"}>
                        Load Older Messages
                    </a>
                </div>
            )}
            <ul>
                {messages &&
                    messages.map((message, index) => {
                        const { messageId, outer_class } = message;
                        const is_last_element = messages.length - 1 === index;
                        const outer_class_classes = is_last_element
                            ? outer_class + " mb-3"
                            : outer_class;

                        return (
                            <span key={messageId}>
                                {unreadIndex === index && unreadMessageHeader}
                                <li className={outer_class_classes} ref={messageRefs[messageId]}>
                                    {renderMessage(message)}
                                </li>
                            </span>
                        );
                    })}
                <div ref={messagesEndRef} />
            </ul>
        </div>
    );
};

ChatBody.propTypes = {
    channel: PropTypes.object.isRequired,
    loadOlderMessages: PropTypes.func.isRequired,
    lastSeenRef: PropTypes.object.isRequired,
    messageRefs: PropTypes.array.isRequired,
    messages: PropTypes.array.isRequired,
    messagesEndRef: PropTypes.object.isRequired,
    unreadIndex: PropTypes.number,
    hasMoreMessages: PropTypes.bool,
};
